<template>
  <div>
    <modal
        name="logosEdit"
        height="auto"
        width="900"
        :scrollable="true"
        :clickToClose="false"
    >
      <CloseModal :close="close"/>
      <div class="modalbox">
        <div class="modalbox-content">
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="`Salvando ${descSingular}`">
              <div class="xrow">
                <div class="col-1-1">
                  <div class="col-1-2">
                    <div class="form-group">
                      <label for="">Descrição</label>
                      <input class="form-control" v-model="item.descricao"/>
                      <div class="message">
                        {{ validation.firstError("item.descricao") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-1-2">
                    <div class="form-group">
                      <label for="">Link</label>
                      <input class="form-control" v-model="item.link"/>
                    </div>
                  </div>
                </div>
                <div class="col-1-1">
                  <div class="col-1-3">
                    <div class="form-group">
                      <label for="">Ordem</label>
                      <input
                          type="number"
                          class="form-control"
                          v-model="item.ordem"
                      />
                      <div class="message">
                        {{ validation.firstError("item.ordem") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-1-3">
                    <div class="form-group">
                      <label for="">Tipo</label>
                      <select class="form-control" v-model="item.tipo_id">
                        <option
                            @click="setChaveLogo(logoTipo.chave)"
                            v-for="logoTipo in logosTipo"
                            v-bind:key="logoTipo.chave"
                            :value="logoTipo.id"
                        >
                          {{ logoTipo.tipo }}
                        </option>
                      </select>
                      <div class="message">
                        {{ validation.firstError("item.tipo_id") }}
                      </div>
                    </div>
                  </div>
                  <div class="col-1-3">
                    <div class="form-group">
                      <label for="">Ativo</label>
                      <select class="form-control" v-model="item.ativo">
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                      <div class="message">
                        {{ validation.firstError("item.ativo") }}
                      </div>
                    </div>
                  </div>
                  <div v-if="this.isTipoSeguranca()" class="col-1-3">
                    <div class="form-group">
                      <label for="">Conteúdo</label>
                      <select v-model="item.conteudo_tipo" class="form-control" v-on:change="resetFields">
                        <option value="HTML">Html</option>
                        <option value="IMAGEM">Imagem</option>
                        /
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="this.isConteudoHtml() && this.isTipoSeguranca()" class="col-1-1">
                  <div class="form-group mt-3 col-1-1">
                    <editor-conteudo :item="this.item"></editor-conteudo>
                  </div>
                </div>
                <div v-if="!this.isConteudoHtml() || !this.isTipoSeguranca()" class="col-1-1">
                  <div class="col-1-3 add-image">
                    <label>
                      <image-picker
                          label="Adicionar Imagem Desktop"
                          placeholder="Colar URL ou clique ao lado para upload"
                          v-model="item.imagem"
                      />
                    </label>
                    <div class="message">
                      {{ validation.firstError("item.imagem") }}
                    </div>
                  </div>
                  <div class="col-1-3 add-image">
                    <label>
                      <image-picker
                          label="Adicionar Imagem Mobile"
                          placeholder="Colar URL ou clique ao lado para upload"
                          v-model="item.imagem_mobile"
                      />
                    </label>
                    <div class="message">
                      {{ validation.firstError("item.imagem_mobile") }}
                    </div>
                  </div>
                  <div
                      v-if="
                      [
                        'pre_rodape',
                        'pre_vitrine',
                        'entre_vitrine',
                        'pos_vitrine',
                      ].includes(item.chave)
                    "
                      class="col-1-3"
                  >
                    <div class="form-group">
                      <label for="">Largura Ocupada</label>
                      <select class="form-control" v-model="item.largura">
                        <option value="12">100% (1 por linha)</option>
                        <option value="6">50% (2 por linha)</option>
                        <option value="4">33% (3 por linha)</option>
                        <option value="3">25% (4 por linha)</option>
                      </select>
                      <div class="message">
                        {{ validation.firstError("item.largura") }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                    v-if="
                    [
                      'pre_rodape',
                      'pre_vitrine',
                      'entre_vitrine',
                      'pos_vitrine',
                    ].includes(item.chave)
                  "
                    class="col-1-1"
                >
                  <CAlert color="warning" v-bind:visible="true">
                    <CAlertHeading>
                      <h5>
                        <CIcon name="cil-warning"/>
                        Medidas padrões para melhor
                        exibição:
                      </h5>
                    </CAlertHeading>
                    <p>
                      <CIcon name="cil-screen-desktop"/>
                      Pequeno: 270 x 220
                      pixels, Médio: 560 x 220 pixels, Grande: 1140 x 220 pixels
                    </p>
                    <p>
                      <CIcon name="cil-screen-smartphone"/>
                      Pequeno: 160 x 130
                      pixels, Grande: 320 x 130 pixels
                    </p>
                  </CAlert>
                </div>
              </div>
              <div class="col-1-1">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">VersoCommerce</label>
                    <select class="form-control" v-model="item.acesso_site">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">
                      {{ validation.firstError("item.acesso_site") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">VersoApp</label>
                    <select class="form-control" v-model="item.acesso_app">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">
                      {{ validation.firstError("item.acesso_app") }}
                    </div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">(VersoApp)Modo Cliente Final</label>
                    <select
                        class="form-control"
                        v-model="item.acesso_cliente_final"
                    >
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">
                      {{ validation.firstError("item.acesso_cliente_final") }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="xrow edit-buttons">
                <button
                    :class="{
                    'button button-success': true,
                    'button-disabled': validation.hasError(),
                  }"
                    v-on:click="handleSubmit"
                >
                  Salvar
                </button>
                &nbsp;
                <button class="button" v-on:click="close">Fechar</button>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {Validator} from "simple-vue-validator";
import CloseModal from "../../../components/CloseModal";
import ImagePicker from "../../../components/ImagePicker";
import {VueEditor} from "vue2-editor";
import EditorConteudo from "../../../components/EditorConteudo.vue";

export default {
  customToolbar: [
    [{header: [false, 1, 2, 3, 4, 5, 6]}],
    ["bold", "italic", "underline", "strike"],
    [
      {align: ""},
      {align: "center"},
      {align: "right"},
      {align: "justify"},
    ],
    ["blockquote", "code-block"],
    [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
    [{indent: "-1"}, {indent: "+1"}],
    [{color: []}, {background: []}],
    ["link", "image", "video", "code"],
    ["clean"],
  ],
  name: "logosEdit",
  props: ["item", "update", "close", "modulos", "descSingular", "logosTipo"],
  components: {EditorConteudo, CloseModal, ImagePicker, VueEditor},
  validators: {
    "item.descricao": (value) => Validator.value(value).required(),
    "item.ordem": (value) => Validator.value(value).required(),
    "item.tipo_id": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
  },
  methods: {
    setChaveLogo(chave) {
      this.item.chave = chave;
    },
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
    isTipoSeguranca() {
      return this.item.tipo === 'seguranca'
    },
    isConteudoHtml() {
      return this.item.conteudo_tipo === 'HTML'
    },
    resetFields() {
      this.item.imagem = null;
      this.item.imagem_mobile = null;
      this.item.conteudo = '';
    },
  }
};
</script>
