<template>
  <div>
    <LogosEdit
        :item="currentItem"
        :update="updateItem"
        :close="closeEdit"
        :descSingular="descSingular"
        :logosTipo="logosTipo"
    ></LogosEdit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descPlural">
        <LogosTable
            :items="items"
            :openEdit="openEdit"
            :openCreate="openCreate"
            :deleteConfirm="deleteConfirm"
            :logosTipo="logosTipo"
        />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from "just-clone";
import {get, post, put, del} from "@/helpers/apiRequest";
import LogosEdit from "./components/LogosEdit";
import LogosTable from "./components/LogosTable";

const ItemLogo = {
  id: null,
  imagem: null,
  descricao: null,
  ordem: 1,
  ativo: 1,
  link: null,
  chave: null,
  tipo_id: 1,
  largura: 1,
  conteudo_tipo: 'IMAGEM',
  acesso_site: 0,
  acesso_app: 0,
  acesso_cliente_final: 0,
};

export default {
  name: "logos",
  components: {
    LogosEdit,
    LogosTable,
  },
  data() {
    return {
      descPlural: "",
      descSingular: "",
      loading: false,
      items: [],
      modulos: [],
      currentItem: {},
      currentAccess: false,
      logosTipo: [],
    };
  },
  methods: {
    loadDescricaoModulo() {
      const {modulos} = JSON.parse(localStorage.getItem("app"));
      const {descPlural, descSingular} = modulos.find(
          (m) => m.nome === "ADMIN_CONTEUDO_VISUAL"
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadLogos() {
      this.loading = true;
      get("/admin/site-logos")
          .then((json) => {
            this.items = json;
            this.loading = false;
          })
          .catch(() => (this.loading = false));
    },
    loadLogosTipo() {
      get(`/admin/site-logos-tipo`)
          .then((json) => {
            this.logosTipo = json;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    openCreate() {
      this.currentItem = clone(ItemLogo);
      this.$modal.show("logosEdit");
    },
    closeCreate() {
      this.$modal.hide("logosEdit");
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.loading = false;
      this.$modal.show("logosEdit");
    },
    closeEdit() {
      this.$modal.hide("logosEdit");
    },
    doRequest() {
      return this.currentItem.id
          ? put(`/admin/site-logos/${this.currentItem.id}`, this.currentItem)
          : post("/admin/site-logos", this.currentItem);
    },
    updateItem() {
      this.doRequest()
          .then((json) => {
            this.closeEdit();
            this.loadLogos();
            this.$swal({
              title: "Salvo com sucesso",
              text: json.message,
              showCancelButton: false,
              cancelButtonText: "Fechar",
              showConfirmButton: true,
              icon: "success",
            });
          })
          .catch((error) => {
            this.$swal({
              title: "Falha ao Salvar",
              text: error.message,
              showCancelButton: true,
              cancelButtonText: "Fechar",
              showConfirmButton: false,
              icon: "error",
            });
          });
    },
    deleteConfirm(id) {
      this.$swal({
        title: "Confirma Remover ?",
        text: "Essa ação não poderá ser desfeita",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
      })
          .then((res) => {
            if (res.value) {
              this.loading = true;
              del(`/admin/site-logos/${id}`)
                  .then((json) => {
                    this.loading = false;
                    this.$swal({
                      title: "Removido com sucesso",
                      icon: "success",
                    });
                    this.loadLogos();
                  })
                  .catch((err) => {
                    this.loading = false;
                    this.$swal({
                      title: "Falha ao remover",
                      icon: "error",
                    });
                  });
            }
          })
          .catch(() => {
          });
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadLogos();
    this.loadDescricaoModulo();
    this.loadLogosTipo();
  },
};
</script>
